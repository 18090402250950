// src/OrderList.tsx
import React from 'react';
import Logout from './Logout'; // ログアウトボタンをインポート

const OrderList: React.FC = () => {
  const orders = [
    { id: 1, item: 'Apple', quantity: 3 },
    { id: 2, item: 'Orange', quantity: 5 },
    { id: 3, item: 'Banana', quantity: 2 },
  ];

  return (
    <div>
      <h2>注文一覧</h2>
      <ul>
        {orders.map(order => (
          <li key={order.id}>
            {order.item} - 数量: {order.quantity}
          </li>
        ))}
      </ul>
      <Logout /> {/* ログアウトボタンを追加 */}
    </div>
  );
};

export default OrderList;
