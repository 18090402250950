// src/PrivateRoute.tsx
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';

const PrivateRoute: React.FC = () => {
  const [user, setUser] = useState<boolean | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(!!user);
    });

    return () => unsubscribe();
  }, []);

  if (user === null) {
    return <div>Loading...</div>; // 認証状態をチェック中の場合
  }

  return user ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
