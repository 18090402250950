// src/Logout.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from './firebaseConfig';

const Logout: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth); // Firebase からログアウト
      navigate('/'); // ログアウト後にログイン画面へリダイレクト
    } catch (error) {
      console.error('ログアウトに失敗しました', error);
    }
  };

  return (
    <button onClick={handleLogout} style={styles.button}>
      ログアウト
    </button>
  );
};

const styles = {
  button: {
    padding: '10px',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '10px',
  },
};

export default Logout;
